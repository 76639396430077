import L  from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, TileLayer, Popup, useMapEvents } from "react-leaflet";
import IActivityData from 'types/Activity';
import MarkerClusterGroup from "react-leaflet-cluster";

//TODO: check addedMarkers after changing route
interface ActivitiesMapProps {
    activities: Array<IActivityData>
}

const MAP_PADDING_X: number = 25
const MAP_PADDING_Y: number = 25
const DEFAULT_COORDINATES: [number, number] = [53.428543, 14.552812]
const DEFAULT_ZOOM: number = 5

//TODO: move to utils / component
const markerIcon = L.icon({
    iconUrl: 'marker-icon.png',
    iconSize:     [30, 30], 
    //iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
    //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});

function Markers({activities}: ActivitiesMapProps) {
    const map = useMapEvents({})

    let latlngs: [number, number][] = activities.map(item => [
        item.geog.coordinates[0],
        item.geog.coordinates[1] 
    ]);

    if (latlngs.length === 0) {
        map.setView(DEFAULT_COORDINATES, DEFAULT_ZOOM);
    } else {
        map.fitBounds(latlngs, {padding: [MAP_PADDING_X, MAP_PADDING_Y]});
    }

    return <>{activities.map((activity: IActivityData, index: number) => (
            <Marker
                icon={markerIcon}
                key={index}
                position={[activity.geog.coordinates[0], activity.geog.coordinates[1]]}
                // eventHandlers ={{click : (e) => drawCircle(e.latlng)}}
            >
                <Popup>
                    <span>{activity.name}</span>
                </Popup>
            </Marker>
    ))
    }</>
  }

export default function ActivitiesMap({ activities }: ActivitiesMapProps) {
    return (
        <MapContainer>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup chunkedLoading> 
                <Markers activities={activities}/>
            </MarkerClusterGroup>
        </MapContainer>
    );
  }