import http from "http-common";
import IActivityData from "types/Activity";
import IAddActivityData from "types/AddActivity";
import { IEnumStatus } from "types/EnumStatus";
import { defaultLang } from "config";

export interface ActivityStatus {
  id: number;
  name: string;
}

export const SPORTS: Array<IEnumStatus> = [
  { id: 0, name: 'Run', translation: 'Run' },
  { id: 1, name: 'Bike', translation: 'Bike' },
  { id: 2, name: 'Football', translation: 'Football' },
];

const getAll = () => {
  return http.get<Array<IActivityData>>("");
};

const getById = (id: any) => {
  return http.get<IActivityData>(`/${id}`);
};

const create = (data: IAddActivityData) => {
  return http.post<IActivityData>("", data);
};

const getActivityStatuses = (sorted: boolean, lang: string = defaultLang) => {
  return http.get<Array<IEnumStatus>>(`/statuses?sorted=${sorted}&lang=${lang}`);
};

const getActivityVisibilityStatuses = (sorted: boolean, lang: string = defaultLang) => {
  return http.get<Array<IEnumStatus>>(`/visibility-statuses?sorted=${sorted}&lang=${lang}`);
};

const ActivityService = {
  getAll,
  getById,
  create,
  getActivityStatuses,
  getActivityVisibilityStatuses
};

export default ActivityService;