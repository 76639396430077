import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import AddActivity from './components/AddActivity';
import Activity from './components/Activity';
import ActivityList from './components/ActivityList';
import ActivityDataService from './services/ActivityService';
import IActivityData from './types/Activity';

const App: React.FC = () => {
  const [activities, setActivities] = useState<IActivityData[]>([]);

  useEffect(() => {
    ActivityDataService.getAll()
      .then(response => {
        setActivities(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <a href="/activities" className="navbar-brand">
          <img className="logo" src="/brand.png" alt="sport 2G4 logo" />
        </a>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to="/activities" className="nav-link">
              Activities
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/add" className="nav-link">
              Add
            </Link>
          </li>
        </div>
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<ActivityList activities={activities} />} />
          <Route path="/activities" element={<ActivityList activities={activities} />} />
          <Route path="/add" element={<AddActivity />} />
          <Route path="/activities/:id" element={<Activity />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;