import React, { useEffect, useState, ChangeEvent } from "react";
import { SPORTS } from "services/ActivityService";
import ActivityDataService from "services/ActivityService";
import IAddActivityData from 'types/AddActivity';
import ICustomerData from 'types/Customer';
import { IEnumStatus } from 'types/EnumStatus';
import IGeogData from 'types/Geog';
import CreateActivityAddMap from "./CreateActivityAddMap";
import { LatLngExpression } from "leaflet";
import DatePicker from "./utils/DateTimePicker";
import { defaultLang } from 'config';


const AddActivity: React.FC = () => {

  const [statuses, setStatuses] = useState<IEnumStatus[]>([]);
  const [visibilityStatuses, setVisibilityStatuses] = useState<IEnumStatus[]>([]);

  const customerMock: ICustomerData = {
    id: 1001,
    firstName: "Szymon"
    //default coordinates for new activity ?? 
  }

  const [markerCircleGeog, updateMarkerCircleGeog] = useState<IGeogData>({
    coordinates: [0, 0],
    type: "Point"
  })

  const initialActivityState = {
    name: "",
    translation: "",
    sportId: 0,
    // customerId: customerMock.id,
    statusId: 0,
    visibilityStatusId: 0,
    geog: { type: "Point", coordinates: [0, 0] },
    startDate: new Date(),
    endDate: new Date(),
    radius: 0
  };

  const [positionState, updatePositionState] = useState<LatLngExpression>([0, 0])
  const [activity, setActivity] = useState<IAddActivityData>(initialActivityState);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setActivity({ ...activity, [name]: value });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setActivity({ ...activity, [name]: value });
  };

  const handleMarkerChange = (event: { lat: number; lng: number; }) => {
    const { lat, lng } = event;
    const updatedGeog = { type: "Point", coordinates: [lat, lng] };
    setActivity({ ...activity, geog: updatedGeog });
    updateMarkerCircleGeog(updatedGeog);
  };

  const saveActivity = () => {
    const updatedActivity = {
      ...activity,
      geog: {
        type: "Point",
        coordinates: [activity.geog.coordinates[0], activity.geog.coordinates[1]]
      }
    };

    ActivityDataService.create(updatedActivity)
      .then((response: any) => {
        setSubmitted(true);
        // console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const newActivity = () => {
    setActivity(initialActivityState);
    setSubmitted(false);
  };
  
  useEffect(() => {
    const fetchStatuses = async (lang: string = defaultLang) => {
      try {
        const response = await ActivityDataService.getActivityStatuses(true, lang);
        setStatuses(response.data);
      } catch (err) {
        console.error('Failed to fetch activity statuses', err);
      }
    };

    const fetchVisibilityStatuses = async (lang: string = defaultLang) => {
      try {
        const response = await ActivityDataService.getActivityVisibilityStatuses(true, lang);
        setVisibilityStatuses(response.data);
      } catch (err) {
        console.error('Failed to fetch activity visibility statuses', err);
      }
    };

    fetchStatuses();
    fetchVisibilityStatuses();
  }, []);

  return (
    <div className="submit-form">
      {submitted ? (
        <div>
          <h4>You submitted successfully!</h4>
          <button className="btn btn-success" onClick={newActivity}>
            Add
          </button>
        </div>
      ) : (
        <>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="name">Activity Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                required
                value={activity.name}
                onChange={handleInputChange}
                name="name"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="statusId">Status</label>
              <select
                id="statusId"
                name="statusId"
                value={activity.statusId}
                onChange={handleSelectChange}
                className="form-control"
                required>
                {statuses.map(status => (
                  <option key={status.id} value={status.id}>
                  {status.translation}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="visibilityStatusId">Visibility status</label>
              <select
                id="visibilityStatusId"
                name="visibilityStatusId"
                value={activity.visibilityStatusId}
                onChange={handleSelectChange}
                className="form-control"
                required>
                {visibilityStatuses.map(v_status => (
                  <option key={v_status.id} value={v_status.id}>
                  {v_status.translation}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="sportId">Sport</label>
              <select
                id="sportId"
                name="sportId"
                value={activity.sportId}
                onChange={handleSelectChange}
                className="form-control"
                required>
                {SPORTS.map((option) => (
                  <option key={option.id} value={option.id} className="form-control">{option.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="radius">Radius [km]</label>
              <input
                type="number"
                className="form-control"
                id="radius"
                required
                value={activity.radius}
                onChange={handleInputChange}
                name="radius"
                step={0.5}
                min={0}
              />
            </div>

            <DatePicker label={'Start date'} />

            <button onClick={saveActivity} className="btn btn-success">
              Submit
            </button>
          </div>
          <div className="col-md-8">
            <CreateActivityAddMap radius={activity.radius} onMarkerChange={handleMarkerChange} />
          </div>
        </>
      )}
    </div>
  );
};

export default AddActivity;