import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityDataService from 'services/ActivityService';
import IActivityData from 'types/Activity';
import ActivitiesMap from './ActivitiesMap';

type Props = {
  activities: Array<IActivityData>;
};

type State = {
  activities: Array<IActivityData>,
  currentActivity: IActivityData | null,
  currentIndex: number
};

export default class ActivityList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.retrieveActivities = this.retrieveActivities.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveActivity = this.setActiveActivity.bind(this);
    this.removeAllActivities = this.removeAllActivities.bind(this);

    this.state = {
      activities: [],
      currentActivity: null,
      currentIndex: -1
    };
  }

  componentDidMount() {
    this.retrieveActivities();
  }

  retrieveActivities() {
    ActivityDataService.getAll()
      .then((response: any) => {
        this.setState({
          activities: response.data
        });
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveActivities();
    this.setState({
      currentActivity: null,
      currentIndex: -1
    });
  }

  setActiveActivity(activity: IActivityData, index: number) {
    this.setState({
      currentActivity: activity,
      currentIndex: index
    });
  }

  removeAllActivities() {
    console.log("Not implemented yet");
  }

  render() {
    const { activities, currentActivity, currentIndex } = this.state;

    // Filter activities to ensure they have valid latitude and longitude
    const validActivities = activities.filter(activity => 
      activity.geog?.coordinates?.[0] !== undefined && activity.geog?.coordinates?.[1] !== undefined
    );

    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            {/* Add any additional UI elements here */}
          </div>
        </div>
        <div className="col-md-12">
          {currentActivity ? (
            <div>
              <h4>Activity</h4>
              <div>
                <label>
                  <strong>Id:</strong>
                </label>{" "}
                {currentActivity.id}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentActivity.status}
              </div>
              <div>
                <label>
                  <strong>Visibility status:</strong>
                </label>{" "}
                {currentActivity.visibilityStatus}
              </div>

              <Link
                to={"/activities/" + currentActivity.id}
                className="badge badge-warning"
              >
                Edit
              </Link>
            </div>
          ) : (
            <ActivitiesMap activities={validActivities} />
          )}
        </div>
      </div>
    );
  }
}