import L, { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMapEvents, } from "react-leaflet";
import IGeogData from "types/Geog";

const markerIcon = L.icon({
    iconUrl: 'marker-icon.png',
    iconSize:     [30, 30], 
    //iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
    //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const markerOptions = {
    // draggable: true,
    autoPan: true,
    icon: markerIcon
}

const CreateActivityAddMap: React.FC<{onMarkerChange:Function, radius:number}> = ({onMarkerChange, radius}) => {
    // const coordinatesFromProps : number[] = props | 

    const [position, updatePosition] = useState<LatLngExpression>([0,0])
    const [markerAdded, setMarkerAdded] = useState<boolean>(false)
    const [markerS] = useState<L.Marker>(new L.Marker([0, 0], markerOptions))
    const [markerSCircle] = useState<L.Circle>(new L.Circle([0, 0], {radius:radius*1000}))

    function AddMarker() {
        const map = useMapEvents({
            click(e) {
                markerS.setLatLng(e.latlng)
                markerSCircle.setLatLng(e.latlng)
                updatePosition(e.latlng);
                onMarkerChange(markerS.getLatLng())
                if(!markerAdded){
                    map.addLayer(markerS)
                    map.addLayer(markerSCircle)
                    setMarkerAdded(true)
                    // updateGeogMockState()
                }
            },
            locationfound(e) {
                map.flyTo(e.latlng, 15, {animate: false})
                markerS.setLatLng(e.latlng)
                markerSCircle.setLatLng(e.latlng)
                updatePosition(e.latlng);
                onMarkerChange(markerS.getLatLng())
                map.addLayer(markerS);
                map.addLayer(markerSCircle)
                setMarkerAdded(true)
            }
        })
        if(!markerAdded){
            map.locate()
            markerS.on('dragend', function(e){
                onMarkerChange(markerS.getLatLng())
            })
        }

        useEffect(() => {
            markerSCircle.setRadius(radius*1000)
        }, [radius]); 

        return <></>
    }
    
    return (
        <MapContainer center={position} zoom={2}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        <AddMarker />
        </MapContainer>
    );
  }
export default CreateActivityAddMap;