import { Component, ChangeEvent } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';


export default class Activity extends Component{
  
    render() {  
      return (
        <div>siemka</div>
      )
    }
  }